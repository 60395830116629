import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Pattern} from '../interfaces/pattern';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfiguratorService {
    currentStep = 0;
    lastSelectedStep = 0;
    stepVariants = new BehaviorSubject<{ order: number, variants: Array<any>, selected_variant: any }>(null);
    nextStep = new Subject();
    multipumpData = null;

    langList = [
        {alias: 'en', title: 'English'},
        {alias: 'ru', title: 'Русcкий'}
    ]
    domain = 'https://hc.hydrosila.com';

    // domain = 'https://hydrosila.configurator.mazurik';

    constructor(private http: HttpClient) {
    }

    getRegions(): Promise<any> {
        return this.http.get<any>(`${this.domain}/api/v1/get_regions/`).toPromise();
    }

    getPatternCodeForMultipumps(data): Promise<Pattern> {
        return this.http.post<Pattern>(`${this.domain}/api/v1/form_section_data/`, data).toPromise();
    }

    getPatternData(type: string): Promise<Pattern> {
        return this.http.get<Pattern>(`${this.domain}/api/v1/get_patterns_data/${type}`).toPromise();
    }

    checkDimensionFileExists(dimensionCode: string): Promise<any> {
        const data = {code: dimensionCode};
        return this.http.post<any>(`${this.domain}/api/v1/check_file/`, data).toPromise();
    }

    getDimensionFile(dimensionCode: string): Promise<any> {
        const data = {code: dimensionCode};
        return this.http.post<any>(`${this.domain}/api/v1/get_file/`, data).toPromise();
    }

    getListDisplacementAndPressure(data: any = null): Promise<any> {
        if (data === null) {
            return this.http.get<any>(`${this.domain}/api/v1/get_all_disp_pressure`).toPromise();
        }
        return this.http.post<any>(`${this.domain}/api/v1/disp_pressure_detail`, data).toPromise();
    }

    sendContactForm(data: any): Promise<any> {
        return this.http.post<any>(`${this.domain}/api/v1/save_contact_form`, data).toPromise();
    }

    refresh() {
        this.currentStep = 0;
        this.lastSelectedStep = 0;
    }
}
