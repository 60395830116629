import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'configurator';
  defaultLang = 'ru';

  constructor(private translate: TranslateService) {
    const lang = localStorage.getItem('dafault_lang');

    if (!!lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      translate.setDefaultLang(this.defaultLang);
      translate.use(this.defaultLang);
      localStorage.setItem('dafault_lang', this.defaultLang);
    }
  }
}
