import {Component, OnInit, OnDestroy} from '@angular/core';
import {ConfiguratorService} from '../../services/configurator.service';
import {Pattern} from '../../interfaces/pattern';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-configurator',
    templateUrl: './configurator.component.html',
    styleUrls: ['./configurator.component.scss']
})

export class ConfiguratorComponent implements OnInit, OnDestroy {
    patternData: Pattern = null;
    variants: any = null;
    code: string = null;
    dimensionCode: string = null;
    showResult = false;
    lang = '';
    subscriptions: Subscription = new Subscription();
    showContactModal = false;

    constructor(private confService: ConfiguratorService,
                private route: ActivatedRoute,
                private translate: TranslateService,
                private router: Router) {

        this.lang = translate.currentLang;
        this.subscriptions.add(translate.onLangChange.subscribe((data) => {
                this.lang = data.lang;
            })
        );

    }

    async ngOnInit() {
        const params = this.route.snapshot.paramMap.get('code');
        let data = null;
        if (params === 'tandem') {
            data = this.confService.multipumpData;
            if (!data) {
                this.router.navigate(['/tandem']);
            }

            data.title.ru = 'Насос секционный серии К';
            data.title.en = 'Multiple pumps series K';
            data.title_image.ru = 'assets/img/multipump.png';
            data.title_image.en = 'assets/img/multipump.png';
            data.image.ru = 'assets/img/multipump_big.png';
            data.image.en = 'assets/img/multipump_big.png';
        } else {
            data = await this.confService.getPatternData(!!params ? params : 'GP1K');
            if (!data) {
                this.router.navigate(['/configurator']);
            }
        }

        this.patternData = data;
        this.code = data.code;

        this.subscriptions.add(this.confService.stepVariants.subscribe(value => {
                if (!!value) {
                    this.variants = value;
                }
            })
        );
    }

    selectVariant(selectedOption) {
        // @ts-ignore

        let mustBeClear = false;
        for (let i = 0; i < this.patternData.sections.length; i++) {
            const step = this.patternData.sections[i];

            if (mustBeClear) {
                step.selected_option = undefined;
                continue;
            }

            if (step.order === this.variants.order) {
                step.selected_option = selectedOption;
                // @ts-ignore
                step.options = step.options.map(value => {
                    value.selected = value.code === selectedOption.code;
                    return value;
                });
                mustBeClear = true;
            }
            this.patternData.sections[i] = step;
        }

        this.getCode(this.patternData.sections);
    }

    getCode(sections) {
        this.code = this.patternData.code;
        this.dimensionCode = this.patternData.code;
        let dimensionBuffer = '';
        let separatorFlag = true;
        let separatorSymbol = '';
        sections.map(data => {
            if (data.separator) {
                separatorFlag = true;
                separatorSymbol = data.separator; // сохраняем символ разделения, на случай если он не "-"
            }

            if (!!data.selected_option && !!data.selected_option.code) {
                let separator = '';
                if (separatorFlag) {
                    separator = separatorSymbol;
                    separatorFlag = false;
                }
                this.code += separator + data.selected_option.code;
                if (data.depend_dimension === 1) {
                    this.dimensionCode += dimensionBuffer + separator + data.selected_option.code;
                    dimensionBuffer = '';
                } else {
                    dimensionBuffer += separator + '*';
                }

                // собираем параметры
                data.selected_option.params.map(param => {
                    const paramSeparator = !!param.separator ? param.separator : '';

                    this.code += paramSeparator + param.value;

                    if (data.depend_dimension === 1) {
                        this.dimensionCode += paramSeparator + param.value;
                    } else {
                        dimensionBuffer += paramSeparator + '*';
                    }
                });
            }

        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
