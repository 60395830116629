import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation} from 'angular-animations';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ConfiguratorService} from '../../services/configurator.service';

@Component({
    selector: 'app-contact-data-modal',
    templateUrl: './contact-data-modal.component.html',
    styleUrls: ['./contact-data-modal.component.scss'],
    animations: [slideInDownOnEnterAnimation(), slideOutDownOnLeaveAnimation()]
})

export class ContactDataModalComponent implements OnInit, OnDestroy {

    @Input() code: string;
    @Output() close = new EventEmitter();
    @Output() sent = new EventEmitter<any>();

    form = new FormGroup({
        full_name: new FormControl('', [Validators.required, Validators.minLength(4)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{12}')]),
        region_id: new FormControl('', Validators.required),
        type: new FormControl(),
        mark: new FormControl(),
        model: new FormControl(),
        application: new FormControl(),
        engine: new FormControl(),
        engine_rpm: new FormControl(),
        other: new FormControl(),
    });

    btnSubmitted = false;
    showDetail = true;
    lang = '';
    subscribtions: Subscription = new Subscription();
    regions: any = null;

    constructor(private translate: TranslateService, private confService: ConfiguratorService) {
        this.lang = translate.currentLang;
        this.subscribtions.add(
            translate.onLangChange.subscribe((data) => {
                this.lang = data.lang;
            })
        );
        this.confService.getRegions().then(data => {
            this.regions = data;
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscribtions.unsubscribe();
    }

    dataSubmit() {
        this.btnSubmitted = true;
        if (this.form.status === 'VALID') {
            // обязательная передача кода продукта вместе с формой
            this.form.value.code = this.code;
            this.sent.emit(this.form.value);
        }
    }

}
