import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfiguratorService} from '../../../services/configurator.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  languages: Array<any> = [];
  lang = '';
  subscribtions: Subscription = new Subscription();

  constructor(private configuratorService: ConfiguratorService,
              private translate: TranslateService) {

    this.languages = this.configuratorService.langList;

    this.lang = translate.currentLang;
    this.subscribtions.add(translate.onLangChange.subscribe((data) => {
        this.lang = data.lang;
      })
    );

  }

  ngOnInit() {
  }

  changeLanguage(lang: string): void {
    localStorage.setItem('dafault_lang', lang);
    this.translate.use(lang);
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }

}
