import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ConfiguratorComponent} from './pages/configurator/configurator.component';
import {PatternComponent} from './pages/pattern/pattern.component';
import {TandemComponent} from './pages/tandem/tandem.component';


const routes: Routes = [
  {path: 'configurator', component: PatternComponent},
  {path: 'configurator/:code', component: ConfiguratorComponent},
  {path: 'tandem', component: TandemComponent},

  {path: '', redirectTo: 'configurator', pathMatch: 'full'},
  {path: '**', redirectTo: 'configurator', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
