import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatternService} from '../../services/pattern.service';
import {
    fadeInDownOnEnterAnimation,
    fadeOutOnLeaveAnimation
} from 'angular-animations';
import {ConfiguratorService} from '../../services/configurator.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-pattern',
    templateUrl: './pattern.component.html',
    styleUrls: ['./pattern.component.scss'],
    animations: [fadeInDownOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class PatternComponent implements OnInit, OnDestroy {
    patternsData = null;
    lang = '';
    subscribtions: Subscription = new Subscription();

    constructor(private patternService: PatternService,
                private confService: ConfiguratorService,
                private translate: TranslateService,
                private router: Router) {
        this.lang = translate.currentLang;
        this.subscribtions.add(
            translate.onLangChange.subscribe((data) => {
                this.lang = data.lang;
            })
        );
    }

    ngOnInit() {
        this.confService.refresh();
        this.patternService.getPatternsList().then(data => {
            this.patternsData = data;
            this.patternsData[0].patterns.push(
                {
                    code: 'TANDEM',
                    title: {
                        en: 'Multiple pumps series K',
                        ru: 'Насос секционный серии "K"',
                    },
                    title_image: {
                        en: 'assets/img/multipump.png',
                        ru: 'assets/img/multipump.png',
                    },
                    image: {
                        en: 'assets/img/multipump_big.png',
                        ru: 'assets/img/multipump_big.png'
                    }
                }
            );
        });
    }

    runConfigurator(code) {
        if (code === 'TANDEM') {
            this.router.navigate(['/tandem']);
        } else {
            this.router.navigate(['/configurator/' + code]);
        }
    }

    ngOnDestroy() {
        this.subscribtions.unsubscribe();
    }

}
