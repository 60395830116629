import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfiguratorComponent} from './pages/configurator/configurator.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatStepperModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StepsComponent} from './components/steps/steps.component';
import {ConfiguratorService} from './services/configurator.service';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {VariantsComponent} from './components/variants/variants.component';
import {ResultComponent} from './components/result/result.component';
import {HeaderComponent} from './components/layouts/header/header.component';
import {PatternComponent} from './pages/pattern/pattern.component';
import {DotsPipe} from './pipes/dots.pipe';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ContactDataModalComponent } from './components/contact-data-modal/contact-data-modal.component';
import { SuccsessModalComponent } from './components/succsess-modal/succsess-modal.component';
import {TandemComponent} from './pages/tandem/tandem.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ConfiguratorComponent,
    StepsComponent,
    VariantsComponent,
    ResultComponent,
    HeaderComponent,
    PatternComponent,
    TandemComponent,
    DotsPipe,
    ContactDataModalComponent,
    SuccsessModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ConfiguratorService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
