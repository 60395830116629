import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';

@Component({
    selector: 'app-success-modal',
    templateUrl: './succsess-modal.component.html',
    styleUrls: ['./succsess-modal.component.scss']
})
export class SuccsessModalComponent implements OnInit {
    @Output() close = new EventEmitter();
    @Input() senderStatus: any;

    constructor() {
    }

    ngOnInit() {
    }

}
