import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PatternService {

  constructor(private http: HttpClient) { }

  getPatternsList(): Promise<any> {
    return this.http.get<any>('https://hc.hydrosila.com/api/v1/get_patterns_list').toPromise();
  }
}
