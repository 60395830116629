import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {fadeInDownOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ConfiguratorService} from '../../services/configurator.service';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
    animations: [fadeInDownOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class ResultComponent implements OnInit, OnDestroy {
    @Input() code: string;
    @Input() dimensionCode: string;
    @Input() sections: any;
    @Input() img: string;
    @Input() title: string;
    showCopiedPopup = false;
    showContactFormModal = false;
    showContactSentModal = false;
    senderStatus: any = null;
    dimensionFileExist: any = null;
    dimensionFile: any = null;
    lang = '';
    subscribtions: Subscription = new Subscription();

    constructor(private translate: TranslateService, private confService: ConfiguratorService) {
        this.lang = translate.currentLang;
        this.subscribtions.add(translate.onLangChange.subscribe((data) => {
                this.lang = data.lang;
            })
        );
    }

    ngOnInit() {
        this.confService.checkDimensionFileExists(this.dimensionCode).then(response => {
            if (!!response && response.hasOwnProperty('exist')) {
                this.dimensionFileExist = response;
            }
        });

        console.log(this.sections);
    }

    getDimensionFile() {
        this.confService.getDimensionFile(this.dimensionCode).then(response => {
            if (!!response && response.hasOwnProperty('file')) {
                this.dimensionFile = response;
                window.open(this.dimensionFile.file, '_blank');
            }
        });
    }

    copy() {
        this.showCopiedPopup = true;
        const event = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', this.code);
            e.preventDefault();
            document.removeEventListener('copy', event);
        };
        document.addEventListener('copy', event);
        document.execCommand('copy');
        setTimeout(() => this.showCopiedPopup = false, 5000);
    }

    gotoContactModal() {
        this.showContactFormModal = true;
    }

    sent(data: any) {
        this.confService.sendContactForm(data).then(
            response => {
                this.showContactFormModal = false;
                this.showContactSentModal = true;
                this.senderStatus = true;
            },
            error => {
                this.showContactFormModal = false;
                this.showContactSentModal = true;
                this.senderStatus = false;
            },
        );
    }

    print() {
        window.print();
    }

    ngOnDestroy() {
        this.subscribtions.unsubscribe();
    }

}
