import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  fadeInDownOnEnterAnimation,
  fadeOutOnLeaveAnimation
} from 'angular-animations';
import {ConfiguratorService} from '../../services/configurator.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tandem',
  templateUrl: './tandem.component.html',
  styleUrls: ['./tandem.component.scss'],
  animations: [fadeInDownOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class TandemComponent implements OnInit, OnDestroy {
  pumpAmount = 2;
  minPumpAmount = 2;
  maxPumpAmount = 4;
  isAddSectionBtnActive = true;
  isConfiguratorBtnActive = false;
  pumpData = [];
  selection = [];
  code = '';
  lang = '';
  subscribtions: Subscription = new Subscription();

  constructor(private confService: ConfiguratorService,
              private translate: TranslateService,
              private router: Router) {
    this.lang = translate.currentLang;
    this.subscribtions.add(
      translate.onLangChange.subscribe((data) => {
        this.lang = data.lang;
      })
    );
  }

  ngOnInit() {
    this.confService.refresh();

    this.pumpAmount = this.minPumpAmount;

    this.confService.getListDisplacementAndPressure().then(list => {
      this.pumpData.push({
        data: list,
        displacementList: list.map(item => item.displacement),
        pressureList: [],
        active: true,
        pattern: '',
        code: '',
        images: [],
        selected: {
          displacement: '',
          pressure: ''
        }
      });

      // init empty blocks
      for (let i = 1; i < this.pumpAmount; i++) {
        this.pumpData.push({
          data: null,
          displacementList: [],
          pressureList: [],
          active: false,
          pattern: '',
          code: '',
          images: [],
          selected: {
            displacement: '',
            pressure: ''
          }
        });
      }

    });
  }

  changeDisplacement(displacement, i) {
    this.isConfiguratorBtnActive = false;

    this.pumpData[i].selected.displacement = displacement;
    this.pumpData[i].selected.pressure = '';
    this.pumpData[i].pattern = '';
    this.pumpData[i].code = '';
    this.pumpData[i].images = [];
    this.pumpData[i].pressureList = [];
    for (const item of this.pumpData[i].data) {
      if (item.displacement === displacement) {
        this.pumpData[i].pressureList.push(...item.pressure);
      }
    }
    this.pumpData[i].selected.pressure = '';

    // обнуляем выбор у всех следующих секций
    for (let j = i + 1; j < this.pumpAmount; j++) {
      this.pumpData[j].active = false;
      this.pumpData[j].pattern = '';
      this.pumpData[j].code = '';
      this.pumpData[j].images = [];
      this.pumpData[j].selected = {
        displacement: '',
        pressure: ''
      };
    }
  }

  updatePatternCode() {
    this.selection = [];
    this.confService.multipumpData = null;
    this.code = '';
    for (const item of this.pumpData) {
      if (item.active && item.code) {
        if (this.code === '') {
          this.code += item.code;
        } else {
          this.code += '/' + item.code.substring(2);
        }
        this.selection.push({
          pattern_code: item.pattern,
          displacement: item.selected.displacement
        });
      }
    }

    if (!!this.pumpData[this.pumpAmount - 1].selected.pressure) {
      this.confService.getPatternCodeForMultipumps(this.selection).then(data => {
        this.confService.multipumpData = data;
      });
    }
  }

  changePressure(pressure, i) {
    this.pumpData[i].selected.pressure = pressure;
    this.confService.getListDisplacementAndPressure(this.pumpData[i].selected).then(data => {
      this.pumpData[i].pattern = data.pattern;
      this.pumpData[i].code = data.code;
      this.pumpData[i].images = data.image;
      if (i + 1 < this.pumpAmount) {
        this.pumpData[i + 1] = {
          data: data.options,
          displacementList: data.options.map(item => item.displacement),
          pressureList: [],
          active: true,
          pattern: '',
          code: '',
          images: [],
          selected: {
            displacement: '',
            pressure: ''
          }
        };
      }

      // обнуляем выбор у всех следующих секций
      for (let j = i + 2; j < this.pumpAmount; j++) {
        this.pumpData[j].active = false;
        this.pumpData[j].pattern = '';
        this.pumpData[j].code = '';
        this.pumpData[j].images = [];
        this.pumpData[j].selected = {
          displacement: '',
          pressure: ''
        };
      }
      this.updateStatus();
    });

    return true;
  }

  updateStatus() {
    this.pumpAmount = this.pumpData.length;
    this.isAddSectionBtnActive = (this.pumpAmount < this.maxPumpAmount);
    this.isConfiguratorBtnActive = !!this.pumpData[this.pumpAmount - 1].selected.pressure;
    this.updatePatternCode();
  }

  addSection() {
    if (this.isAddSectionBtnActive) {
      this.pumpData.push({
        data: null,
        displacementList: [],
        pressureList: [],
        active: false,
        pattern: '',
        code: '',
        images: [],
        selected: {
          displacement: '',
          pressure: ''
        }
      });
      this.updateStatus();
      if (!!this.pumpData[this.pumpAmount - 2].selected.pressure) {
        this.changePressure(this.pumpData[this.pumpAmount - 2].selected.pressure, this.pumpAmount - 2);
      }
    }

    this.updateStatus();
  }

  removeSection() {
    if (this.pumpData.length >= this.minPumpAmount) {
      this.pumpData.pop();
    }
    this.updateStatus();
  }

  runConfigurator() {
    if (this.isConfiguratorBtnActive) {
      this.router.navigate(['/configurator/tandem']);
    }
  }

  ngOnDestroy() {
    this.subscribtions.unsubscribe();
  }


}
