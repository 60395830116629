import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {Step} from '../../interfaces/step';
import {ConfiguratorService} from '../../services/configurator.service';
import {fadeInLeftOnEnterAnimation} from 'angular-animations';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
    animations: [fadeInLeftOnEnterAnimation()]
})
export class StepsComponent implements OnInit, OnDestroy {
    @Output() showResult = new EventEmitter<boolean>();
    _steps: Array<Step>;
    isResult = false;
    lang = '';
    subscriptions: Subscription = new Subscription();

    constructor(public confService: ConfiguratorService,
                private translate: TranslateService) {
        this.lang = translate.currentLang;
        this.subscriptions.add(translate.onLangChange.subscribe((data) => {
                this.lang = data.lang;
            })
        );

    }

    @Input('steps') set getSteps(value) {
        if (!!value) {
            this._steps = value;
        }
    }

    ngOnInit() {
        this.confService.stepVariants.next(
            {order: this._steps[0].order, variants: this._steps[0].options, selected_variant: null}
        );

        this.subscriptions.add(
            this.confService.nextStep.subscribe(() => {
                if (this.confService.currentStep + 1 === this._steps.length) {
                    this.confService.lastSelectedStep = this._steps.length + 1;
                    this.confService.currentStep = this._steps.length + 1;
                    this.resultEmit(true);
                    this.isResult = true;
                } else {
                    this.changeStep(this.confService.currentStep, false);
                }
            })
        );
    }

    changeStep(step: number, disable: boolean) {
        this.isResult = false;
        this.showResult.emit(false);

        if (!(disable)) {
            setTimeout(() => {

                // Получаем все варианты на данном шаге
                let filteredVariants = this._steps[step + 1].options;
                const selectedOptions = [];

                // Получаем все выбранные опции конфигуратора
                for (const item of this._steps) {
                    if (item.selected_option) {
                        selectedOptions.push(item);
                    }
                }

                // Исключаем те варианты, у которых установлена зависимость
                // и она не совпадает с выбранными опциями:
                filteredVariants = filteredVariants.filter(variant => {
                    // если вариант не имеет зависимостей - показать
                    if (variant.dependencies.length === 0) {
                        return true;
                    }

                    // если вариант ИМЕЕТ зависимости, но ещё ничего не выбрано (глюк?) - скрыть
                    if (selectedOptions.length === 0) {
                        return false;
                    }

                    // группируем данные по группам
                    const dependencies = {};
                    for (const dep of variant.dependencies) {
                        if (!dependencies[dep.section_alias]) {
                            dependencies[dep.section_alias] = [];
                        }
                        dependencies[dep.section_alias].push(dep.code);
                    }

                    // Выбираем вариант, если он имеет хотя бы одну зависимость, выбранную раньше,
                    // но обязательно минимум одну в каждой группе
                    for (const alias in dependencies) {
                        if (!dependencies.hasOwnProperty(alias)) {
                            continue;
                        }

                        let optFlag = false;
                        for (const opt of selectedOptions) {
                            if (alias === opt.alias && dependencies[alias].indexOf(opt.selected_option.code) > -1) {
                                optFlag = true;
                                break;
                            }
                        }

                        if (!optFlag) {
                            return false;
                        }
                    }

                    return true;
                });

                this.confService.stepVariants.next(
                    {order: this._steps[step + 1].order, variants: filteredVariants, selected_variant: null}
                );
                this.confService.currentStep = step + 1;
                this.resultEmit(false);
                if (step > this.confService.lastSelectedStep) {
                    this.confService.lastSelectedStep = step;
                }
            });
        }
    }

    resultEmit(val: boolean) {
        if (this.confService.currentStep >= this._steps.length) {
            this.isResult = val;
            this.showResult.emit(val);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
